export const CURRENT_CONFIG = {

  // license
  appId: '133590', // You need to go to the development website to apply.
  appKey: 'e570d79228ca4c26ca8a74d7ce24e4f', // You need to go to the development website to apply.
  appLicense: 'FJxSwyx2CrXfJZkG8RYR6euwOHe7SeygM3o9oINXfQgQjk4ME6E+IgKUzTBpbiu/RJOHRU2cnWvTJKGZqLCMTvf6s+8nMec2vusri1g+GxVf4jdogDm8pbijRgE+eZGYSAWHUhPbttWuXjsNKk0gW728uRW3HypICICdAmQvwbI=', // You need to go to the development website to apply.

  // http
  baseURL: 'http://178.128.127.168:6789', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'Please enter the WebSocket access address.', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'Please enter the rtmp access address.', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  amapKey: 'Please enter the amap key.',

  // kiagri api
  kApiUrl: 'http://178.128.127.168:6789',
}
